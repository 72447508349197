// import React from "react";
// // import { useState } from "react";
// import {
//   Card,
//   CardBody,
//   // Col,
//   // Dropdown,
//   // DropdownMenu,
//   // DropdownItem,
//   // DropdownToggle,
// } from "reactstrap";
// import SalesAnalyticsChart from "../AllCharts/SalesAnalyticsChart";

// // const SalesAnalytics = props => {
// //     const [menu, setMenu] = useState(false)

// const SalesAnalytics = ({ tollPlazaData }) => {
//   // Destructure tollPlazaData from props
//   // const [menu, setMenu] = useState(false);

//   if (!tollPlazaData || !tollPlazaData.totalTrafficData) {
//     // return <div>Loading...</div>; // Handle the loading state appropriately
//     return null; // Render nothing
//   }

//   const TotalTrafficData = tollPlazaData.totalTrafficData;

  
//   return (
//     <React.Fragment>
//       {/* <Col xl={8}> */}
//       <Card className="card-height-100">
//         <CardBody>
//           {/* <div className="float-end">
//             <Dropdown isOpen={menu} toggle={() => setMenu(!menu)}>
//               <DropdownToggle className="dropdown-toggle text-reset" tag="a">
//                 <span className="fw-semibold">Sort By:</span>{" "}
//                 <span className="text-muted">
//                   All<i className="mdi mdi-chevron-down ms-1"></i>
//                 </span>
//               </DropdownToggle>

//               <DropdownMenu className="dropdown-menu dropdown-menu-end">
//                 <DropdownItem> All</DropdownItem>
//                 <DropdownItem>LHR to ISB</DropdownItem>
//                 <DropdownItem>ISB to LHR</DropdownItem>
//               </DropdownMenu>
//             </Dropdown>
//           </div> */}
//           <h4 className="card-title mb-4">Traffic Statistics</h4>

// {TotalTrafficData.map((totaltraffic,index) => (
     
//   <div className="mt-1" key={index}>
//             <ul className="list-inline main-chart mb-0">
//               {/* <li className="list-inline-item chart-border-left me-0 border-0">
//                 <h3 className="text-primary">
//                   <span data-plugin="counterup">2,371</span>
//                   <span className="text-muted d-inline-block fw-normal font-size-15 ms-3">
//                     Total Vehicle Count
//                   </span>
//                 </h3>
//               </li> */}
//               <li className="list-inline-item chart-border-left me-0">
//                 <h3>
//                   <span data-plugin="counterup"></span>
//                   <span className="text-muted d-inline-block fw-normal font-size-15 ms-3">
//                     {/* Peak Traffic Hours */}
//                     {totaltraffic.MostCommonVehicleType}
//                   </span>
//                 </h3>
//               </li>
//               <li className="list-inline-item chart-border-left me-0">
//                 <h3>
//                   <span data-plugin="counterup">Car</span>
//                   <span className="text-muted d-inline-block fw-normal font-size-15 ms-3">
//                     {/* Most Common Vehicle Type */}
//                     {totaltraffic.PeakTrafficHour}
//                   </span>
//                 </h3>
//               </li>
//               <li className="list-inline-item chart-border-left me-0">
//                 <h3>
//                   <span data-plugin="counterup">Traffic Flow Ratio</span>
//                   <span className="text-muted d-inline-block fw-normal font-size-15 ms-3">
//                     LHR to ISB 1:9
//                   </span>
//                 </h3>
//               </li>
//             </ul>
//           </div>

// ))}
          

//           <div className="mt-3">
//             <SalesAnalyticsChart tollPlazaData={tollPlazaData} />
//           </div>
//         </CardBody>
//       </Card>
//       {/* </Col> */}
//     </React.Fragment>
//   );
// };

// export default SalesAnalytics;



import React from "react";
import {
  Card,
  CardBody,
} from "reactstrap";
import SalesAnalyticsChart from "../AllCharts/SalesAnalyticsChart";

const SalesAnalytics = ({ tollPlazaData }) => {
  // Ensure tollPlazaData is correctly structured
  if (!tollPlazaData || !tollPlazaData.totalTrafficData) {
    return <div>Loading...</div>; // Handle the loading state appropriately
  }

  const totalTrafficData = tollPlazaData.totalTrafficData;

  return (
    <React.Fragment>
      <Card className="card-height-100">
        <CardBody>
          <h4 className="card-title mb-4">Traffic Statistics</h4>

          <div className="mt-1">
            <ul className="list-inline main-chart mb-0">
              <li className="list-inline-item chart-border-left me-0">
                <h3>
                  <span data-plugin="counterup">{totalTrafficData.MostCommonVehicleType}</span>
                  <span className="text-muted d-inline-block fw-normal font-size-15 ms-3">
                    Most Common Vehicle Type
                  </span>
                </h3>
              </li>
              <li className="list-inline-item chart-border-left me-0">
                <h3>
                  <span data-plugin="counterup">{totalTrafficData.PeakTrafficHour}</span>
                  <span className="text-muted d-inline-block fw-normal font-size-15 ms-3">
                    Peak Traffic Hour
                  </span>
                </h3>
              </li>
              <li className="list-inline-item chart-border-left me-0">
                <h3>
                  <span data-plugin="counterup">Traffic Flow Ratio</span>
                  <span className="text-muted d-inline-block fw-normal font-size-15 ms-3">
                    LHR: {totalTrafficData.TrafficFlowRatio.LHR}, ISB: {totalTrafficData.TrafficFlowRatio.ISB}
                  </span>
                </h3>
              </li>
            </ul>
          </div>

          <div className="mt-3">
            <SalesAnalyticsChart tollPlazaData={tollPlazaData} />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default SalesAnalytics;
