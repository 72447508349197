// // // Combined Data

// // import React from "react";
// // import CountUp from "react-countup";
// // import { Card, CardBody, Col, Row } from "reactstrap";
// // // import combinedDataset from './Data/combinedData'; // Adjust the path as necessary

// // const Widget = ({ tollPlazaData,totalVehicleCount }) => {
// //   if (!tollPlazaData || !tollPlazaData.widgets) {
// //     // return <div>Loading...</div>; // Handle the loading state appropriately
// //      return null;
// //   }

// //   const widgets = tollPlazaData.widgets; // Use widgets from combinedDataset
// //   const widgetTotalVehicleCount = tollPlazaData.totalVehicleCount;
// //   console.log("Widgets",widgets);
// //   console.log("TotalVehicle count ",totalVehicleCount)
// //   return (
// //     <React.Fragment>
// //       <Row>
// //         {widgets.map((widget, key) => (
// //           <Col md={6} xl={3} key={key}>
// //             <Card>
// //               <CardBody>
// //                 <div className="float-end">
// //                   <div className="avatar-sm mx-auto mb-4">
// //                     <span className="avatar-title rounded-circle bg-light font-size-24">
// //                       <i className={widget.icon}></i>
// //                     </span>
// //                   </div>
// //                 </div>
// //                 {/* <div>
// //                   <p className="text-muted text-uppercase fw-semibold font-size-13">
// //                     {widget.title}
// //                   </p>
// //                   <h4 className="mb-1 mt-1">
// //                     {widget.dollar && ""}
// //                     <span className="counter-value" data-target="58425">
// //                       <CountUp end={widget.count} />
// //                     </span>
// //                   </h4>
// //                 </div> */}

// //                 <div>
// //                   <p className="text-muted text-uppercase fw-semibold font-size-13">
// //                     {widget.title}
// //                   </p>
// //                   <h4 className="mb-1 mt-1">
// //                     {widget.title === "Total Vehicles" ? (
// //                       <CountUp end={widgetTotalVehicleCount} />
// //                     ) : (
// //                       <CountUp end={widget.count} />
// //                     )}
// //                   </h4>
// //                 </div>
// //                 <p className="text-muted mt-3 mb-0">
// //                   <span
// //                     className={"badge badge-soft-" + widget.color + " me-1"}
// //                   >
// //                     {widget.upArrow ? (
// //                       <i className="mdi mdi-arrow-up-bold me-1"></i>
// //                     ) : (
// //                       <i className="mdi mdi-arrow-down-bold me-1"></i>
// //                     )}
// //                     {widget.percentage}
// //                   </span>{" "}
// //                   Last synced 40 minutes ago
// //                 </p>
// //               </CardBody>
// //             </Card>
// //           </Col>
// //         ))}
// //       </Row>
// //     </React.Fragment>
// //   );
// // };

// // export default Widget;















// // import React from "react";
// // import CountUp from "react-countup";
// // import { Card, CardBody, Col, Row } from "reactstrap";

// // const Widget = ({ tollPlazaData, totalVehicleCount }) => {
// //   if (!tollPlazaData || !tollPlazaData.widgets) {
// //     return null; // Handle loading state appropriately
// //   }

// //   const widgets = tollPlazaData.widgets;
// // console.log("WWWW TOLlPLaza",tollPlazaData)
// //   console.log("Widgets", widgets);
// //   console.log("Total Vehicle Count", totalVehicleCount);

// //   return (
// //     <React.Fragment>
// //       <Row>
// //         {widgets.map((widget) => (
// //           <Col md={6} xl={3} key={widget.id} >
// //             <Card>
// //               <CardBody>
// //                 <div className="float-end"   >
// //                   <div className="avatar-sm mx-auto mb-4">
// //                     <span className="avatar-title rounded-circle bg-light font-size-24">
// //                       <i className={widget.icon}></i>
// //                     </span>
// //                   </div>
// //                 </div>
// //                 <div>
// //                   <p className="text-muted text-uppercase fw-semibold font-size-13">
// //                     {widget.title}
// //                   </p>
// //                   <h4 className="mb-1 mt-1">
// //                     {widget.title === "Total Vehicle Count" ? (
// //                       <CountUp end={totalVehicleCount} />
// //                     ) : (
// //                       <CountUp end={widget.count} />
// //                     )}
// //                   </h4>
// //                 </div>
// //                 <p className="text-muted mt-3 mb-0">
// //                   <span className={"badge badge-soft-" + widget.color + " me-1"}>
// //                     {widget.upArrow ? (
// //                       <i className="mdi mdi-arrow-up-bold me-1"></i>
// //                     ) : (
// //                       <i className="mdi mdi-arrow-down-bold me-1"></i>
// //                     )}
// //                     {widget.percentage}
// //                   </span>
// //                   Last synced 40 minutes ago
// //                 </p>
// //               </CardBody>
// //             </Card>
// //           </Col>
// //         ))}
// //       </Row>
// //     </React.Fragment>
// //   );
// // };

// // export default Widget;




// import React from "react";
// import CountUp from "react-countup";
// import { Card, CardBody, Col, Row } from "reactstrap";

// const Widget = ({ tollPlazaData, totalVehicleCount }) => {
//   if (!tollPlazaData) {
//     return null; // Handle loading state appropriately
//   }



//   // Destructure specific metrics from tollPlazaData
//   const { totalISBTOLHR, totalLHRTOISB, busyPlaza , widgets} = tollPlazaData;

//     console.log("totalvehicleCountSADADADADADDA",totalVehicleCount)

//   const widgetTitle1 = widgets[0]?.title;
//   const widgetTitle2 = widgets[1]?.title;
//   const widgetTitle3 = widgets[2]?.title;
//   const widgetTitle4 = widgets[3]?.title;

// const widgetIcon1 = widgets[0]?.icon;
// // const widgetPercentage1 = widgets[0]?.percentage;

// const widgetIcon2 = widgets[1]?.icon;
// // const widgetPercentage2 = widgets[1]?.percentage;

// const widgetIcon3 = widgets[2]?.icon;
// // const widgetPercentage3 = widgets[2]?.percentage;

// const widgetIcon4 = widgets[3]?.icon;
// // const widgetPercentage4 = widgets[3]?.percentage;



  

//   return (
//     <React.Fragment>
//       <Row>
//         {/* Widget for Total Vehicle Count */}
//         <Col md={6} xl={3}>
//           <Card>
//             <CardBody>
//             <div className="float-end"   >
//                   <div className="avatar-sm mx-auto mb-4">
//                     <span className="avatar-title rounded-circle bg-light font-size-24">
//                       <i className={widgetIcon1}></i>
//                     </span>
//                   </div>
//                 </div>
//               <div>
//               <p className="text-muted text-uppercase fw-semibold font-size-13">
//                    {widgetTitle1}
//                 </p>
//                 <h4 className="mb-1 mt-1">
//                   <CountUp end={totalVehicleCount} />
//                 </h4>
//                 <p className="text-muted mt-3 mb-0">
//                 <span className="badge badge-soft-danger me-1">
//                     {/* <i className="mdi mdi-arrow-down-bold me-1"></i>  */}
//                     {/* {widgetPercentage1} */}
//                     ...
//                   </span>
//                   Last synced 40 minutes ago
//                 </p>
//               </div>
//             </CardBody>
//           </Card>
//         </Col>

//         {/* Widget for totalISBTOLHR */}
//         <Col md={6} xl={3}>
//           <Card>
//             <CardBody>
//               <div className="float-end">
//                 <div className="avatar-sm mx-auto mb-4">
//                 <span className="avatar-title rounded-circle bg-light font-size-24">
//                       <i className={widgetIcon2}></i>
//                     </span>
//                 </div>
//               </div>
//               <div>
//               <p className="text-muted text-uppercase fw-semibold font-size-13">
//                    {widgetTitle3}
//                 </p>
//                 <h4 className="mb-1 mt-1">
//                   <CountUp end={totalISBTOLHR} />
//                 </h4>
//                 <p className="text-muted mt-3 mb-0">
//                 <span className="badge badge-soft-danger me-1">
//                     {/* <i className="mdi mdi-arrow-down-bold me-1"></i>  */}
//                     {/* {widgetPercentage2} */}
//                     ...
//                   </span>
//                   Last synced 40 minutes ago
//                 </p>
//               </div>
//             </CardBody>
//           </Card>
//         </Col>

//         {/* Widget for totalLHRTOISB */}
//         <Col md={6} xl={3}>
//           <Card>
//             <CardBody>
//               <div className="float-end">
//                 <div className="avatar-sm mx-auto mb-4">
//                 <span className="avatar-title rounded-circle bg-light font-size-24">
//                       <i className={widgetIcon3}></i>
//                     </span>
//                 </div>
//               </div>
//               <div>
//               <p className="text-muted text-uppercase fw-semibold font-size-13">
//                    {widgetTitle2}
//                 </p>
//                 <h4 className="mb-1 mt-1">
//                   <CountUp end={totalLHRTOISB} />
//                 </h4>
//                 <p className="text-muted mt-3 mb-0">
//                 <span className="badge badge-soft-danger me-1">
//                     {/* <i className="mdi mdi-arrow-down-bold me-1"></i>  */}
//                     {/* {widgetPercentage3} */}
//                   ...
//                   </span>
//                   Last synced 40 minutes ago
//                 </p>
//               </div>
//             </CardBody>
//           </Card>
//         </Col>

//         {/* Widget for busyPlaza (Avg Traffic) */}
//         <Col md={6} xl={3}>
//           <Card>
//             <CardBody>
//               <div className="float-end">
//                 <div className="avatar-sm mx-auto mb-4">
//                 <span className="avatar-title rounded-circle bg-light font-size-24">
//                       <i className={widgetIcon4}></i>
//                     </span>
//                 </div>
//               </div>
//               <div>
//               <p className="text-muted text-uppercase fw-semibold font-size-13">
//                    {widgetTitle4}
//                 </p>
//                 <h4 className="mb-1 mt-1">
//                   <CountUp end={busyPlaza} />
//                 </h4>
//                 <p className="text-muted mt-3 mb-0">
//                 <span className="badge badge-soft-danger me-1">
//                     {/* <i className="mdi mdi-arrow-down-bold me-1"></i>  */}
//                     {/* {widgetPercentage4} */}
//                   ...
//                   </span>
//                   Last synced 40 minutes ago
//                 </p>
//               </div>
//             </CardBody>
//           </Card>
//         </Col>
//       </Row>
//     </React.Fragment>
//   );
// };

// export default Widget;








import React from "react";
import CountUp from "react-countup";
import { Card, CardBody, Col, Row } from "reactstrap";

const Widget = ({ tollPlazaData, totalVehicleCount }) => {
  if (!tollPlazaData) {
    return null; // Handle loading state appropriately
  }

  // Destructure specific metrics from tollPlazaData
  const { totalISBTOLHR, totalLHRTOISB, busyPlaza, widgets } = tollPlazaData;

  console.log("totalvehicleCountSADADADADADDA!!!!!!!!!!!!!!!!!!!", totalVehicleCount);

  console.log("totalISBTOLHR!!!!!!!!!!!!!",totalISBTOLHR);
  console.log("totalLhrTOISB!!!!!!!!!!!",totalLHRTOISB)

  const widgetTitle1 = widgets[0]?.title;
  const widgetTitle2 = widgets[1]?.title;
  
  const widgetTitle3 = widgets[2]?.title;
  const widgetTitle4 = widgets[3]?.title;

  const widgetIcon1 = widgets[0]?.icon;
  // const widgetPercentage1 = widgets[0]?.percentage;

  const widgetIcon2 = widgets[1]?.icon;
  // const widgetPercentage2 = widgets[1]?.percentage;

  const widgetIcon3 = widgets[2]?.icon;
  // const widgetPercentage3 = widgets[2]?.percentage;

  const widgetIcon4 = widgets[3]?.icon;
  // const widgetPercentage4 = widgets[3]?.percentage

  // Calculate the average of totalISBTOLHR and totalLHRTOISB
  const averageTraffic = (totalISBTOLHR + totalLHRTOISB) / 2;

  return (
    <React.Fragment>
      <Row>
        {/* Widget for Total Vehicle Count */}
        <Col md={6} xl={3}>
          <Card>
            <CardBody>
              <div className="float-end">
                <div className="avatar-sm mx-auto mb-4">
                  <span className="avatar-title rounded-circle bg-light font-size-24">
                    <i className={widgetIcon1}></i>
                  </span>
                </div>
              </div>
              <div>
                <p className="text-muted text-uppercase fw-semibold font-size-13">
                  {widgetTitle1}
                </p>
                <h4 className="mb-1 mt-1">
                  <CountUp end={totalVehicleCount} />
                </h4>
                <p className="text-muted mt-3 mb-0">
                  <span className="badge badge-soft-danger me-1">
                    {/* <i className="mdi mdi-arrow-down-bold me-1"></i> */}
                    {/* {widgetPercentage1} */}
                    ...
                  </span>
                  Last synced 40 minutes ago
                </p>
              </div>
            </CardBody>
          </Card>
        </Col>

        {/* Widget for totalISBTOLHR */}
        <Col md={6} xl={3}>
          <Card>
            <CardBody>
              <div className="float-end">
                <div className="avatar-sm mx-auto mb-4">
                  <span className="avatar-title rounded-circle bg-light font-size-24">
                    <i className={widgetIcon2}></i>
                  </span>
                </div>
              </div>
              <div>
                <p className="text-muted text-uppercase fw-semibold font-size-13">
                  {widgetTitle3}
                </p>
                <h4 className="mb-1 mt-1">
                  <CountUp end={totalISBTOLHR} />
                </h4>
                <p className="text-muted mt-3 mb-0">
                  <span className="badge badge-soft-danger me-1">
                    {/* <i className="mdi mdi-arrow-down-bold me-1"></i> */}
                    {/* {widgetPercentage2} */}
                    ...
                  </span>
                  Last synced 40 minutes ago
                </p>
              </div>
            </CardBody>
          </Card>
        </Col>

        {/* Widget for totalLHRTOISB */}
        <Col md={6} xl={3}>
          <Card>
            <CardBody>
              <div className="float-end">
                <div className="avatar-sm mx-auto mb-4">
                  <span className="avatar-title rounded-circle bg-light font-size-24">
                    <i className={widgetIcon3}></i>
                  </span>
                </div>
              </div>
              <div>
                <p className="text-muted text-uppercase fw-semibold font-size-13">
                  {widgetTitle2}
                </p>
                <h4 className="mb-1 mt-1">
                  <CountUp end={totalLHRTOISB} />
                </h4>
                <p className="text-muted mt-3 mb-0">
                  <span className="badge badge-soft-danger me-1">
                    {/* <i className="mdi mdi-arrow-down-bold me-1"></i> */}
                    {/* {widgetPercentage3} */}
                    ...
                  </span>
                  Last synced 40 minutes ago
                </p>
              </div>
            </CardBody>
          </Card>
        </Col>

        {/* Widget for busyPlaza (Avg Traffic) */}
        <Col md={6} xl={3}>
          <Card>
            <CardBody>
              <div className="float-end">
                <div className="avatar-sm mx-auto mb-4">
                  <span className="avatar-title rounded-circle bg-light font-size-24">
                    <i className={widgetIcon4}></i>
                  </span>
                </div>
              </div>
              <div>
                <p className="text-muted text-uppercase fw-semibold font-size-13">
                  {widgetTitle4}
                </p>
                <h4 className="mb-1 mt-1">
                  <CountUp end={averageTraffic} />
                </h4>
                <p className="text-muted mt-3 mb-0">
                  <span className="badge badge-soft-danger me-1">
                    {/* <i className="mdi mdi-arrow-down-bold me-1"></i> */}
                    {/* {widgetPercentage4} */}
                    ...
                  </span>
                  Last synced 40 minutes ago
                </p>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Widget;










