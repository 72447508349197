import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Container, Row } from "reactstrap";
import SalesAnalytics from "./SalesAnalytics";
import TimestampTraffic from "./TimestampTraffic";
import TotalVehicleCount from "./TotalVehicleCount";
import Widget from "./Widget";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import AllTollPlaza from "./AllTollPlaza";
import Welcome from "./Welcome";
import Loader from "./Loader";

const Dashboard = ({ selectedGroup, optionGroup, tollPlazaData, handleSelectGroup }) => {
  const [showWelcome, setShowWelcome] = useState(true);
  const [loading, setLoading] = useState(false); // Initial loading state set to false

  useEffect(() => {
    console.log("Selected Group:", selectedGroup);
    console.log("Option Group:", optionGroup);
    console.log("Toll Plaza Data:", tollPlazaData);

    // Set loading state to true whenever selectedGroup changes
    if (selectedGroup) {
      setShowWelcome(false);
      setLoading(true); // Start loading

      // Simulate a data fetch or handle real data fetching here
      // This is where you would normally fetch new data based on the selected group
      const fetchData = async () => {
        // Simulate a delay for fetching data
        await new Promise(resolve => setTimeout(resolve, 1000)); // Simulating fetch delay
        setLoading(false); // End loading after fetching
      };

      fetchData();
    } else {
      setShowWelcome(true); // Show welcome if no group is selected
      setLoading(false); // Ensure loading is false if no selection
    }
  }, [selectedGroup, optionGroup]); // Removed tollPlazaData from dependencies


  const totalVehicleCount = tollPlazaData?.totalVehicleCount || 0; 

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Pages" breadcrumbItem="Dashboard" />

          {/* Show Welcome component only if showWelcome is true */}
          {showWelcome && <Welcome />}

          {/* Show loader if loading */}
          {loading && !showWelcome && (
            <Row className="justify-content-center align-items-center" style={{ height: '100vh' }}>
              <Col className="d-flex justify-content-center">
                <Loader />
              </Col>
            </Row>
          )}

          {/* Render components only if not loading */}
          {!loading && !showWelcome && (
            <>
            
              <Row id="TollPlaza">
                <Widget tollPlazaData={tollPlazaData}  totalVehicleCount={totalVehicleCount}  />
              </Row>

              <Row>
                <Col xl={8}>
                  <SalesAnalytics tollPlazaData={tollPlazaData} />
                </Col>
                <Col xl={4}>
                  <TotalVehicleCount tollPlazaData={tollPlazaData} />
                </Col>
              </Row>

              <Row>
                <Col xl={6}>
                  <AllTollPlaza tollPlazaData={tollPlazaData} selectedGroup={selectedGroup} />
                </Col>
                <Col xl={6}>
                  <TimestampTraffic tollPlazaData={tollPlazaData} />
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  selectedGroup: PropTypes.object,
  optionGroup: PropTypes.array,
  handleSelectGroup: PropTypes.func,
  tollPlazaData: PropTypes.any,
};

export default withTranslation()(Dashboard);
