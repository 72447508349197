import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Container, Row } from "reactstrap";
import SalesAnalytics from "./SalesAnalytics";
import TimestampTraffic from "./TimestampTraffic";
import TotalVehicleCount from "./TotalVehicleCount";
import Widget from "./Widget";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import AllTollPlaza from "./AllTollPlaza";
import Welcome from "./Welcome";
import Loader from "./Loader";


const Dashboard = ({ selectedGroup, optionGroup, tollPlazaData, handleSelectGroup }) => {
  const [showWelcome, setShowWelcome] = useState(true);
  const [loading, setLoading] = useState(false);
  const [vehicleWithHighestCount, setVehicleWithHighestCount] = useState(null);
  const [normalizedRatio, setNormalizedRatio] = useState(""); // State to store normalized ratio

  useEffect(() => {
    if (selectedGroup) {
      setShowWelcome(false);
      setLoading(true);

      const fetchData = async () => {
        await new Promise(resolve => setTimeout(resolve, 1000)); // Simulate fetch delay
        setLoading(false);
      };

      fetchData();
    } else {
      setShowWelcome(true);
      setLoading(false);
    }
  }, [selectedGroup, optionGroup]);

  // Function to handle normalized ratio from child component
  const handleNormalizedRatio = (ratio) => {
    console.log("Received Normalized Ratio:", ratio); // Log ratio to the console
    setNormalizedRatio(ratio); // Optionally set it in the state if needed
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Pages" breadcrumbItem="Dashboard" />

          {/* Show Welcome component only if showWelcome is true */}
          {showWelcome && <Welcome />}

          {/* Show loader if loading */}
          {loading && !showWelcome && (
            <Row className="justify-content-center align-items-center" style={{ height: '100vh' }}>
              <Col className="d-flex justify-content-center">
                <Loader />
              </Col>
            </Row>
          )}

          {/* Render components only if not loading */}
          {!loading && !showWelcome && (
            <>
               

              <Row id="TollPlaza">
                <Widget tollPlazaData={tollPlazaData} totalVehicleCount={tollPlazaData?.totalVehicleCount || 0} />
              </Row>

              <Row>
                <Col xl={8}>
                  <SalesAnalytics tollPlazaData={tollPlazaData}  vehicleWithHighestCount={vehicleWithHighestCount}  normalizedRatio={normalizedRatio}  />
                </Col>
                <Col xl={4}>
                  <TotalVehicleCount 
                    tollPlazaData={tollPlazaData} 
                    onVehicleCountLogged={setVehicleWithHighestCount} 
                    onRatioLogged={handleNormalizedRatio} // Pass the handler to child component
                  />
                </Col>
              </Row>

              <Row>
                <Col xl={6}>
                  <AllTollPlaza tollPlazaData={tollPlazaData} selectedGroup={selectedGroup}  totalVehicleCount={tollPlazaData?.totalVehicleCount || 0}  />
                </Col>
                <Col xl={6}>
                  <TimestampTraffic tollPlazaData={tollPlazaData}  totalVehicleCount={tollPlazaData?.totalVehicleCount || 0} />
                </Col>
              </Row>

            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  selectedGroup: PropTypes.object,
  optionGroup: PropTypes.array,
  handleSelectGroup: PropTypes.func,
  tollPlazaData: PropTypes.any,
};

export default withTranslation()(Dashboard);
