


// import React from "react";

// const Welcome = () => {
//   return (
//     <div className="container mt-5 text-center">
//       <h1 className="mb-4" style={{ fontWeight: "bold", color: "black" }}>
//         Welcome to the Toll Plaza Dashboard
//       </h1>
//       <h4 className="text-xl" style={{ color: "#7F8C8D" }}>
//         Streamline your operations with our centralized toll data management solution.
//       </h4>
//       <p className="mt-3" style={{ color: "#7F8C8D", fontSize: "1.1rem" }}>
//       Effectively manage and analyze toll data with intuitive dropdown menus
//          designed for specific filtering. Experience real-time updates through
//          interactive graphs, counts, and tables, all tailored to meet the unique
//          requirements of your operations.
//       </p>
//       {/* <p className="mt-3" style={{ color: "#7F8C8D", fontSize: "1.1rem" }}>
//         Our platform provides insights that drive decision-making and optimize revenue, ensuring a seamless user experience with a responsive design.
//       </p>
//       <p className="mt-3" style={{ color: "#7F8C8D", fontSize: "1.1rem" }}>
//         Join us in enhancing your toll management operations with confidence and ease.
//       </p> */}
//     </div>
//   );
// };

// export default Welcome;

import React from "react";

const Welcome = () => {
  return (
    <div 
      className="container mt-5 text-left" 
      style={{
        padding: "2rem", 
        backgroundColor: "white", 
        borderRadius: "8px", 
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)"
      }}
    >
      <h1 className="mb-4" style={{ fontWeight: "bold", color: "black" }}>
        Welcome to the Toll Plaza Dashboard
      </h1>
      <h4 className="text-xl" style={{ color: "#7F8C8D" }}>
        Elevate toll management with a robust data analytics platform.
      </h4>
      <p className="mt-3" style={{ color: "#7F8C8D", fontSize: "1.1rem", lineHeight: "1.5" }}>
        Gain insights into vehicle counts, flow rates, and total vehicle statistics. The dashboard provides real-time data visualizations, including graphs and tables, to facilitate performance tracking with ease.
      </p>
      <p className="mt-3" style={{ color: "#7F8C8D", fontSize: "1.1rem", lineHeight: "1.5" }}>
        Analyze traffic patterns and vehicle classifications using intuitive dropdown menus, enabling filtering and viewing of specific data pertinent to toll plaza operations. Informed decision-making is supported by accurate and up-to-date information.
      </p>
      <p className="mt-3" style={{ color: "#7F8C8D", fontSize: "1.1rem", lineHeight: "1.5" }}>
        The platform is designed for a seamless user experience, ensuring easy access to insights that optimize revenue and enhance operational efficiency.
      </p>
    </div>
  );
};

export default Welcome;
