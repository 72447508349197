import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

// Import fake backend
import fakeBackend from "./helpers/AuthType/fakeBackend";

// Activating fake backend
fakeBackend();

const App = (props) => {
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [optionGroup, setOptionGroup] = useState([
    { label: "-- All Traffic --", options: [] },
  ]);
  const [tollPlazaData, setTollPlazaData] = useState(null);

  useEffect(() => {
    // fetch("http://localhost:4001/api/tollplazas/get-folder-names", { method: "GET" })
    fetch("https://trafficapp.applivity.com/api/tollplazas/get-folder-names", {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        const tollPlazaOptions = data.map((plazaName) => ({
          label: plazaName,
          value: plazaName,
        }));
        setOptionGroup([{ label: "", options: tollPlazaOptions }]);
      })
      .catch((error) => console.error("Error fetching TollPlaza:", error));
  }, []);

  const handleSelectGroup = (selectedOption) => {
    setSelectedGroup(selectedOption);
    if (selectedOption) {
      const plazaName = encodeURIComponent(selectedOption.value);

      // fetch(`http://localhost:4001/api/tollplazas/combined-data?plaza=${plazaName}`, { method: "GET" })
      fetch(
        `https://trafficapp.applivity.com/api/tollplazas/combined-data?plaza=${plazaName}`,
        { method: "GET" }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setTollPlazaData(data);
        })
        .catch((error) => console.error("Error fetching dataset:", error));
    } else {
      setTollPlazaData(null);
    }
  };

  


  const getLayout = () => {
    switch (props.layout.layoutType) {
      case "horizontal":
        return HorizontalLayout;
      default:
        return VerticalLayout;
    }
  };

  const Layout = getLayout();

  return (
    <React.Fragment>
      <Routes>
        <Route>
          {authRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<NonAuthLayout>{route.component}</NonAuthLayout>}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}
        </Route>

        <Route>
          {userRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <Layout
                  selectedGroup={selectedGroup}
                  optionGroup={optionGroup}
                  handleSelectGroup={handleSelectGroup}
                  tollPlazaData={tollPlazaData}
                >
                  <Authmiddleware>
                    {React.cloneElement(route.component, {
                      selectedGroup,
                      optionGroup,
                      handleSelectGroup,
                      tollPlazaData,
                    })}
                  </Authmiddleware>
                </Layout>
              }
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Route>
      </Routes>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);






