/// Combined Data

import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../Dashboard/Style/Style.css";
// import combinedDataset from './Data/combinedData'; // Adjust the path as necessary

const TimestampTraffic = ({ tollPlazaData }) => {
  // Check if vehicleCountData is valid
  if (!tollPlazaData || !tollPlazaData.timeStampData) {
    // return <div>Loading...</div>; // Handle the loading state appropriately
     return null;
  }

  const TimeStampData = tollPlazaData.timeStampData; // Use timeStampData from combinedDataset

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h3" className="mb-4">
          TimeStamp
        </CardTitle>
        <div className="table-responsive">
          <table className="table table-striped table-custom table-bordered mb-0">
            <thead>
              <tr>
                <th>Time</th>
                <th>Total Vehicle</th>
                <th>LHR-ISB</th>
                <th>ISB-LHR</th>
              </tr>
            </thead>
            <tbody>
              {TimeStampData.map((TimeStamp, index) => (
                <tr key={index}>
                  <td>{TimeStamp.Time}</td>
                  <td>{TimeStamp.TotalVehicle}</td>
                  <td>{TimeStamp.LHRTOISB}</td>
                  <td>{TimeStamp.ISBTOLHR}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
};

export default TimestampTraffic;
