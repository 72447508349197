// /// Combined Data

// import React from "react";
// import { Card, CardBody, CardTitle } from "reactstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min";
// import "../Dashboard/Style/Style.css";
// // import combinedDataset from './Data/combinedData'; // Adjust the path as necessary

// const TimestampTraffic = ({ tollPlazaData , totalVehicleCount   }) => {
//   // Check if vehicleCountData is valid
//   if (!tollPlazaData || !tollPlazaData.timeStampData) {
//     // return <div>Loading...</div>; // Handle the loading state appropriately
//      return null;
//   }

//   const TimeStampData = tollPlazaData.timeStampData; // Use timeStampData from combinedDataset

//   return (
//     <Card>
//       <CardBody>
//         <CardTitle tag="h3" className="mb-4">
//           TimeStamp
//         </CardTitle>
//         <div className="table-responsive">
//           <table className="table table-striped table-custom table-bordered mb-0">
//             <thead>
//               <tr>
//                 <th>Time</th>
//                 <th>Total Vehicle</th>
//                 <th>LHR-ISB</th>
//                 <th>ISB-LHR</th>
//               </tr>
//             </thead>
//             <tbody>
//               {TimeStampData.map((TimeStamp, index) => (
//                 <tr key={index}>
//                   <td>{TimeStamp.Time}</td>
//                   <td>{TimeStamp.TotalVehicle}</td>
//                   <td>{TimeStamp.LHRTOISB}</td>
//                   <td>{TimeStamp.ISBTOLHR}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </CardBody>
//     </Card>
//   );
// };

// export default TimestampTraffic;























// import React from "react";
// import { Card, CardBody, CardTitle } from "reactstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min";
// import "../Dashboard/Style/Style.css";

// const TimestampTraffic = ({ tollPlazaData, totalVehicleCount }) => {
//   // Check if vehicleCountData is valid
//   if (!tollPlazaData || !tollPlazaData.timeStampData) {
//     return null;
//   }

//   const TimeStampData = tollPlazaData.timeStampData; // Use timeStampData from combinedDataset
//   const numRows = TimeStampData.length; // Number of rows in the timestamp data

//   // Calculate base count for each row and distribute remaining vehicles
//   let remainingTotal = totalVehicleCount;

//   const rowTotals = Array.from({ length: numRows }, (_, index) => {
//     const baseCount = Math.floor(totalVehicleCount / numRows); // Base count for each row (without remainder)
//     const remainder = totalVehicleCount % numRows; // Remainder to distribute

//     // Initially distribute the base count
//     let rowCount = baseCount;

//     // Add one more vehicle to the first `remainder` rows
//     if (index < remainder) {
//       rowCount += 1;
//     }

//     // Store the row count
//     return rowCount;
//   });

//   // Adjust the row totals slightly for variance (like 21, 19, etc.)
//   const adjustedRowTotals = rowTotals.map((rowTotal) => {
//     const variation = Math.floor(Math.random() * 3) - 1; // Random variation between -1, 0, and 1
//     return rowTotal + variation;
//   });

//   // We want to ensure the adjusted row totals sum to `totalVehicleCount`, so we calculate the final sum
//   const finalSum = adjustedRowTotals.reduce((acc, curr) => acc + curr, 0);
//   console.log("Final Sum of Row Totals after adjustment:", finalSum); // This should now match totalVehicleCount

//   return (
//     <Card>
//       <CardBody>
//         <CardTitle tag="h3" className="mb-4">
//           TimeStamp
//         </CardTitle>
//         <div className="table-responsive">
//           <table className="table table-striped table-custom table-bordered mb-0">
//             <thead>
//               <tr>
//                 <th>Time</th>
//                 <th>Total Vehicle</th>
//                 <th>LHR-ISB</th>
//                 <th>ISB-LHR</th>
//               </tr>
//             </thead>
//             <tbody>
//               {TimeStampData.map((TimeStamp, index) => {
//                 // Get the adjusted total for this row
//                 const totalForRow = adjustedRowTotals[index];

//                 // Calculate LHR-ISB and ISB-LHR based on the adjusted total
//                 const lhrIsb = Math.floor(totalForRow / 2);
//                 const isbLhr = totalForRow - lhrIsb;

//                 return (
//                   <tr key={index}>
//                     <td>{TimeStamp.Time}</td>
//                     <td>{totalForRow}</td>  {/* Display the adjusted total vehicle count for this timestamp */}
//                     <td>{lhrIsb}</td>
//                     <td>{isbLhr}</td>
//                   </tr>
//                 );
//               })}
//             </tbody>
//           </table>
//         </div>
//       </CardBody>
//     </Card>
//   );
// };

// export default TimestampTraffic;


















// import React from "react";
// import { Card, CardBody, CardTitle } from "reactstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min";
// import "../Dashboard/Style/Style.css";

// const TimestampTraffic = ({ tollPlazaData, totalVehicleCount }) => {
//   // Check if vehicleCountData is valid
//   if (!tollPlazaData || !tollPlazaData.timeStampData) {
//     return null;
//   }

//   const TimeStampData = tollPlazaData.timeStampData; // Use timeStampData from combinedDataset
//   const numRows = TimeStampData.length; // Number of rows in the timestamp data

//   let remainingTotal = totalVehicleCount;

//   // Calculate row totals with random distribution logic for Total Vehicle
//   const rowTotals = Array.from({ length: numRows }, (_, index) => {
//     const baseCount = Math.floor(totalVehicleCount / numRows); // Base count for each row (without remainder)
//     const remainder = totalVehicleCount % numRows; // Remainder to distribute

//     // Initially distribute the base count
//     let rowCount = baseCount;

//     // Add one more vehicle to the first `remainder` rows
//     if (index < remainder) {
//       rowCount += 1;
//     }

//     // Store the row count
//     return rowCount;
//   });

//   // Apply slight random variations (for example, +-2) to the row totals
//   const adjustedRowTotals = rowTotals.map((rowTotal) => {
//     const variation = Math.floor(Math.random() * 5) - 2; // Random variation between -2, 0, and 2
//     return rowTotal + variation;
//   });

//   // We need to make sure the sum of all adjusted row totals equals totalVehicleCount
//   const finalSum = adjustedRowTotals.reduce((acc, curr) => acc + curr, 0);
//   console.log("Final Sum of Row Totals after adjustment:", finalSum); // This should match totalVehicleCount

//   // Now we need to re-adjust the final sum if necessary (to ensure it equals totalVehicleCount)
//   if (finalSum !== totalVehicleCount) {
//     const diff = totalVehicleCount - finalSum;

//     // Distribute the difference evenly across rows (we can add/subtract 1 from rows)
//     for (let i = 0; i < Math.abs(diff); i++) {
//       adjustedRowTotals[i % numRows] += diff > 0 ? 1 : -1;
//     }
//   }

//   return (
//     <Card>
//       <CardBody>
//         <CardTitle tag="h3" className="mb-4">
//           TimeStamp
//         </CardTitle>
//         <div className="table-responsive">
//           <table className="table table-striped table-custom table-bordered mb-0">
//             <thead>
//               <tr>
//                 <th>Time</th>
//                 <th>Total Vehicle</th>
//                 <th>LHR-ISB</th>
//                 <th>ISB-LHR</th>
//               </tr>
//             </thead>
//             <tbody>
//               {TimeStampData.map((TimeStamp, index) => {
//                 // Get the adjusted total for this row
//                 const totalForRow = adjustedRowTotals[index];

//                 // Generate a random value for LHR-ISB, and calculate ISB-LHR as the remaining
//                 const lhrIsb = Math.floor(Math.random() * totalForRow); // Randomly choose a value between 0 and totalForRow
//                 const isbLhr = totalForRow - lhrIsb; // Ensure the sum is correct

//                 return (
//                   <tr key={index}>
//                     <td>{TimeStamp.Time}</td>
//                     <td>{totalForRow}</td>  {/* Display the adjusted total vehicle count for this timestamp */}
//                     <td>{lhrIsb}</td>  {/* Random split for LHR-ISB */}
//                     <td>{isbLhr}</td>  {/* Remaining value for ISB-LHR */}
//                   </tr>
//                 );
//               })}
//             </tbody>
//           </table>
//         </div>
//       </CardBody>
//     </Card>
//   );
// };

// export default TimestampTraffic;















import React, { useState, useEffect } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../Dashboard/Style/Style.css";

const TimestampTraffic = ({ tollPlazaData, totalVehicleCount }) => {
  // State to store the randomized totals and LHR-ISB/ISB-LHR splits
  const [randomizedTotals, setRandomizedTotals] = useState([]);

  useEffect(() => {
    if (tollPlazaData && tollPlazaData.timeStampData) {
      const TimeStampData = tollPlazaData.timeStampData;
      const numRows = TimeStampData.length; // Number of rows in the timestamp data

      let remainingTotal = totalVehicleCount;

      // Calculate row totals with random distribution logic for Total Vehicle count
      const rowTotals = Array.from({ length: numRows }, (_, index) => {
        const baseCount = Math.floor(totalVehicleCount / numRows); // Base count for each row
        const remainder = totalVehicleCount % numRows; // Remainder to distribute

        let rowCount = baseCount;
        if (index < remainder) {
          rowCount += 1; // Add 1 to the first `remainder` rows
        }

        return rowCount;
      });

      // Apply slight random variations to the row totals (for example, +-2)
      const adjustedRowTotals = rowTotals.map((rowTotal) => {
        const variation = Math.floor(Math.random() * 5) - 2; // Random variation between -2, 0, and 2
        return rowTotal + variation;
      });

      // We need to make sure the sum of all adjusted row totals equals totalVehicleCount
      const finalSum = adjustedRowTotals.reduce((acc, curr) => acc + curr, 0);

      // Adjust if the final sum doesn't match the totalVehicleCount
      if (finalSum !== totalVehicleCount) {
        const diff = totalVehicleCount - finalSum;

        // Distribute the difference evenly across rows (add/subtract 1)
        for (let i = 0; i < Math.abs(diff); i++) {
          adjustedRowTotals[i % numRows] += diff > 0 ? 1 : -1;
        }
      }

      // Generate LHR-ISB and ISB-LHR splits for each row and store in state
      const newRandomizedTotals = adjustedRowTotals.map((totalForRow) => {
        const lhrIsb = Math.floor(Math.random() * totalForRow); // Random split for LHR-ISB
        const isbLhr = totalForRow - lhrIsb; // Remaining value for ISB-LHR
        return { totalForRow, lhrIsb, isbLhr };
      });

      // Store the randomized totals in state
      setRandomizedTotals(newRandomizedTotals);
    }
  }, [tollPlazaData, totalVehicleCount]); // Recalculate when tollPlazaData or totalVehicleCount changes

  // Check if vehicleCountData is valid
  if (!tollPlazaData || !tollPlazaData.timeStampData) {
    return null;
  }

  const TimeStampData = tollPlazaData.timeStampData; // Use timeStampData from the tollPlazaData

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h3" className="mb-4">
          TimeStamp
        </CardTitle>
        <div className="table-responsive">
          <table className="table table-striped table-custom table-bordered mb-0">
            <thead>
              <tr>
                <th>Time</th>
                <th>Total Vehicle</th>
                <th>LHR-ISB</th>
                <th>ISB-LHR</th>
              </tr>
            </thead>
            <tbody>
              {TimeStampData.map((TimeStamp, index) => {
                const { totalForRow, lhrIsb, isbLhr } = randomizedTotals[index] || {};

                return (
                  <tr key={index}>
                    <td>{TimeStamp.Time}</td>
                    <td>{totalForRow}</td> {/* Display the adjusted total vehicle count for this timestamp */}
                    <td>{lhrIsb}</td> {/* Random split for LHR-ISB */}
                    <td>{isbLhr}</td> {/* Remaining value for ISB-LHR */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
};

export default TimestampTraffic;
