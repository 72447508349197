/// Combined Dataset
import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../Dashboard/Style/Style.css";
//  import combinedDataset from './Data/combinedData'; // Adjust the path as necessary

const AllTollPlaza = ({ tollPlazaData , selectedGroup }) => {
  // Check if vehicleCountData is valid
  if (!tollPlazaData || !tollPlazaData.tollPlazaData) {
    // return <div>Loading...</div>; // Handle the loading state appropriately
       return null;
  }

  const AllTollPlazaData = tollPlazaData.tollPlazaData; // Use tollPlazaData from combinedDataset

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h3" className="mb-4">
          {/* All TollPlaza */}
          {selectedGroup ? selectedGroup.value || selectedGroup  : "No Group Selected"}
        </CardTitle>
        <div className="table-responsive">
          <table className="table table-striped table-custom table-bordered mb-0">
            <thead>
              <tr>
                <th>Toll Plaza</th>
                <th>Total Vehicle</th>
                <th>LHR-ISB</th>
                <th>ISB-LHR</th>
              </tr>
            </thead>
            {/* <tbody>
              {AllTollPlazaData.map((TollPlaza, index) => (
                <tr key={index}>
                  <td>{TollPlaza.TollPlaza}</td>
                  <td>{TollPlaza.TotalVehicle}</td>
                  <td>{TollPlaza.LHRTOISB}</td>
                  <td>{TollPlaza.ISBTOLHR}</td>
                </tr>
              ))}
            </tbody> */}
            <tbody>
            {AllTollPlazaData.map((TollPlaza, index) => (
  <tr key={index}>
    <td>{TollPlaza.TollPlaza}</td>
    <td>{TollPlaza.TotalVehicle}</td>
    <td>{TollPlaza.LHRTOISB || 0}</td> {/* Ensure you access this correctly */}
    <td>{TollPlaza.ISBTOLHR || 0}</td> {/* Ensure you access this correctly */}
  </tr>
))}
            </tbody>
           

          </table>
        </div>
      </CardBody>
    </Card>
  );
};

export default AllTollPlaza;












