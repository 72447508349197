// /// Combined Dataset
// import React from "react";
// import { Card, CardBody, CardTitle } from "reactstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min";
// import "../Dashboard/Style/Style.css";
// //  import combinedDataset from './Data/combinedData'; // Adjust the path as necessary

// const AllTollPlaza = ({ tollPlazaData , selectedGroup }) => {
//   // Check if vehicleCountData is valid
//   if (!tollPlazaData || !tollPlazaData.tollPlazaData) {
//     // return <div>Loading...</div>; // Handle the loading state appropriately
//        return null;
//   }

//   const AllTollPlazaData = tollPlazaData.tollPlazaData; // Use tollPlazaData from combinedDataset

//   return (
//     <Card>
//       <CardBody>
//         <CardTitle tag="h3" className="mb-4">
//           {/* All TollPlaza */}
//           {selectedGroup ? selectedGroup.value || selectedGroup  : "No Group Selected"}
//         </CardTitle>
//         <div className="table-responsive">
//           <table className="table table-striped table-custom table-bordered mb-0">
//             <thead>
//               <tr>
//                 <th>Toll Plaza</th>
//                 <th>Total Vehicle</th>
//                 <th>LHR-ISB</th>
//                 <th>ISB-LHR</th>
//               </tr>
//             </thead>
//             {/* <tbody>
//               {AllTollPlazaData.map((TollPlaza, index) => (
//                 <tr key={index}>
//                   <td>{TollPlaza.TollPlaza}</td>
//                   <td>{TollPlaza.TotalVehicle}</td>
//                   <td>{TollPlaza.LHRTOISB}</td>
//                   <td>{TollPlaza.ISBTOLHR}</td>
//                 </tr>
//               ))}
//             </tbody> */}
//             <tbody>
//             {AllTollPlazaData.map((TollPlaza, index) => (
//   <tr key={index}>
//     <td>{TollPlaza.TollPlaza}</td>
//     <td>{TollPlaza.TotalVehicle}</td>
//     <td>{TollPlaza.LHRTOISB || 0}</td> {/* Ensure you access this correctly */}
//     <td>{TollPlaza.ISBTOLHR || 0}</td> {/* Ensure you access this correctly */}
//   </tr>
// ))}
//             </tbody>
           

//           </table>
//         </div>
//       </CardBody>
//     </Card>
//   );
// };

// export default AllTollPlaza;












// import React from "react";
// import { Card, CardBody, CardTitle } from "reactstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min";
// import "../Dashboard/Style/Style.css";

// // Function to divide total into 20 parts with slight variations
// const divideTotalIntoParts = (total) => {
//   const numParts = 20;
//   let baseValue = Math.floor(total / numParts); // Start with base average value
//   let remainder = total - baseValue * numParts; // Calculate the remainder

//   // Create 20 parts with slight random variations around the baseValue
//   let parts = Array.from({ length: numParts }, (_, index) => {
//     let randomVariation = Math.floor(Math.random() * 5) - 2; // Random variation between -2 and 2
//     let partValue = baseValue + randomVariation;
//     return partValue;
//   });

//   // Adjust the parts to match the original total by distributing the remainder
//   let currentSum = parts.reduce((sum, part) => sum + part, 0);
//   let difference = total - currentSum; // How much we need to adjust by

//   for (let i = 0; i < Math.abs(difference); i++) {
//     // Adjust by adding or subtracting 1 from a random part
//     let randomIndex = Math.floor(Math.random() * numParts);
//     parts[randomIndex] += difference > 0 ? 1 : -1;
//   }

//   return parts;
// };



//   const AllTollPlaza = ({ tollPlazaData, selectedGroup }) => {
//     if (!tollPlazaData || !tollPlazaData.tollPlazaData) {
//       return null;
//     }
  
//     const AllTollPlazaData = tollPlazaData.tollPlazaData;
  
//     // Calculate 20 parts of the total vehicle count
//     const totalVehicleCount = tollPlazaData.totalVehicleCount || 0;
//     const parts = Array.from({ length: 20 }, (_, index) => {
//       return Math.floor(totalVehicleCount / 20) + (index < (totalVehicleCount % 20) ? 1 : 0); // Distribute remainder evenly
//     });
  
//     return (
//       <Card>
//         <CardBody>
//           <CardTitle tag="h3" className="mb-4">
//             {selectedGroup ? selectedGroup.value || selectedGroup : "No Group Selected"}
//           </CardTitle>
//           <div className="table-responsive">
//             <table className="table table-striped table-custom table-bordered mb-0">
//               <thead>
//                 <tr>
//                   <th>Toll Plaza</th>
//                   <th>Total Vehicle (Divided)</th>
//                   <th>LHR-ISB</th>
//                   <th>ISB-LHR</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {AllTollPlazaData.map((TollPlaza, index) => {
//                   // Ensure we're dividing the total count into 20 parts
//                   const totalVehiclePart = parts[index] || 0; // Default to 0 if out of range
  
//                   return (
//                     <tr key={index}>
//                       <td>{TollPlaza.TollPlaza}</td>
//                       <td>{totalVehiclePart}</td>
//                       <td>{TollPlaza.LHRTOISB || 0}</td>
//                       <td>{TollPlaza.ISBTOLHR || 0}</td>
//                     </tr>
//                   );
//                 })}
//               </tbody>
//             </table>
//           </div>
//         </CardBody>
//       </Card>
//     );
//   };
  
//   export default AllTollPlaza;
  









// import React from "react";
// import { Card, CardBody, CardTitle } from "reactstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min";
// import "../Dashboard/Style/Style.css";

// // Function to divide total vehicle count into LHR-ISB and ISB-LHR
// const divideTotalIntoLHR_ISB_ISB_LHR = (total) => {
//   // Distribute the total into LHR-ISB and ISB-LHR with some randomness, but ensure the sum is correct
//   const lhrIsb = Math.floor(total / 2); // Split total roughly in half
//   const isbLhr = total - lhrIsb; // Ensure the sum is equal to the total

//   // Slight variation to add randomness
//   const variation = Math.floor(Math.random() * 5) - 2; // Random variation between -2 and 2
//   const adjustedLHR_ISB = lhrIsb + variation;
//   const adjustedISB_LHR = isbLhr - variation;

//   return {
//     lhrIsb: adjustedLHR_ISB,
//     isbLhr: adjustedISB_LHR,
//   };
// };

// const AllTollPlaza = ({ tollPlazaData, selectedGroup ,  totalVehicleCount  }) => {
//   if (!tollPlazaData || !tollPlazaData.tollPlazaData) {
//     return null;
//   }

//   const AllTollPlazaData = tollPlazaData.tollPlazaData;
  
//   // Assuming you want to distribute totalVehicleCount (6830) across multiple rows
//   const TotalVehicleCount = 6830;
//   const numRows = AllTollPlazaData.length; // Number of toll plazas

//   // Split the total vehicles into the appropriate values for each row
//   let totalForRow = totalVehicleCount;
//   const rowTotals = Array.from({ length: numRows }, (_, index) => {
//     // Calculate the total for each row with slight variation
//     let baseTotal = Math.floor(totalForRow / numRows);
//     baseTotal += (index < totalForRow % numRows ? 1 : 0); // Distribute remainder
//     totalForRow -= baseTotal; // Reduce total for next row
//     return baseTotal;
//   });

//   return (
//     <Card>
//       <CardBody>
//         <CardTitle tag="h3" className="mb-4">
//           {selectedGroup ? selectedGroup.value || selectedGroup : "No Group Selected"}
//         </CardTitle>
//         <div className="table-responsive">
//           <table className="table table-striped table-custom table-bordered mb-0">
//             <thead>
//               <tr>
//                 <th>Toll Plaza</th>
//                 <th>Total Vehicle</th>
//                 <th>LHR-ISB</th>
//                 <th>ISB-LHR</th>
//               </tr>
//             </thead>
//             <tbody>
//               {AllTollPlazaData.map((TollPlaza, index) => {
//                 // Get the total for the current row
//                 const totalForRow = rowTotals[index];

//                 // Calculate LHR-ISB and ISB-LHR
//                 const { lhrIsb, isbLhr } = divideTotalIntoLHR_ISB_ISB_LHR(totalForRow);

//                 return (
//                   <tr key={index}>
//                     <td>{TollPlaza.TollPlaza}</td>
//                     <td>{totalForRow}</td>
//                     <td>{lhrIsb}</td>
//                     <td>{isbLhr}</td>
//                   </tr>
//                 );
//               })}
//             </tbody>
//           </table>
//         </div>
//       </CardBody>
//     </Card>
//   );
// };

// export default AllTollPlaza;





// import React from "react";
// import { Card, CardBody, CardTitle } from "reactstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min";
// import "../Dashboard/Style/Style.css";

// // Function to divide total vehicle count into LHR-ISB and ISB-LHR
// const divideTotalIntoLHR_ISB_ISB_LHR = (total) => {
//   // Distribute the total into LHR-ISB and ISB-LHR with some randomness, but ensure the sum is correct
//   const lhrIsb = Math.floor(total / 2); // Split total roughly in half
//   const isbLhr = total - lhrIsb; // Ensure the sum is equal to the total

//   // Slight variation to add randomness
//   const variation = Math.floor(Math.random() * 5) - 2; // Random variation between -2 and 2
//   const adjustedLHR_ISB = lhrIsb + variation;
//   const adjustedISB_LHR = isbLhr - variation;

//   return {
//     lhrIsb: adjustedLHR_ISB,
//     isbLhr: adjustedISB_LHR,
//   };
// };

// const AllTollPlaza = ({ tollPlazaData, selectedGroup, totalVehicleCount }) => {
//   if (!tollPlazaData || !tollPlazaData.tollPlazaData) {
//     return null;
//   }


// console.log("Total vehicle count",totalVehicleCount)

//   const AllTollPlazaData = tollPlazaData.tollPlazaData;
  
//   // Get the number of rows (toll plazas)
//   const numRows = AllTollPlazaData.length; 

//   // Split the total vehicles into the appropriate values for each row
//   let totalForRow = totalVehicleCount;
//   const rowTotals = Array.from({ length: numRows }, (_, index) => {
//     // Calculate the total for each row with slight variation
//     let baseTotal = Math.floor(totalForRow / numRows);
//     baseTotal += (index < totalForRow % numRows ? 1 : 0); // Distribute remainder
//     totalForRow -= baseTotal; // Reduce total for next row
//     return baseTotal;
//   });

//   return (
//     <Card>
//       <CardBody>
//         <CardTitle tag="h3" className="mb-4">
//           {selectedGroup ? selectedGroup.value || selectedGroup : "No Group Selected"}
//         </CardTitle>
//         <div className="table-responsive">
//           <table className="table table-striped table-custom table-bordered mb-0">
//             <thead>
//               <tr>
//                 <th>Toll Plaza</th>
//                 <th>Total Vehicle</th>
//                 <th>LHR-ISB</th>
//                 <th>ISB-LHR</th>
//               </tr>
//             </thead>
//             <tbody>
//               {AllTollPlazaData.map((TollPlaza, index) => {
//                 // Get the total for the current row
//                 const totalForRow = rowTotals[index];

//                 // Calculate LHR-ISB and ISB-LHR
//                 const { lhrIsb, isbLhr } = divideTotalIntoLHR_ISB_ISB_LHR(totalForRow);

//                 return (
//                   <tr key={index}>
//                     <td>{TollPlaza.TollPlaza}</td>
//                     <td>{totalForRow}</td>
//                     <td>{lhrIsb}</td>
//                     <td>{isbLhr}</td>
//                   </tr>
//                 );
//               })}
//             </tbody>
//           </table>
//         </div>
//       </CardBody>
//     </Card>
//   );
// };

// export default AllTollPlaza;












// import React from "react";
// import { Card, CardBody, CardTitle } from "reactstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min";
// import "../Dashboard/Style/Style.css";

// // Function to divide total vehicle count into LHR-ISB and ISB-LHR
// const divideTotalIntoLHR_ISB_ISB_LHR = (total) => {
//   // Distribute the total into LHR-ISB and ISB-LHR with some randomness, but ensure the sum is correct
//   const lhrIsb = Math.floor(Math.random() * total); // Random value between 0 and total
//   const isbLhr = total - lhrIsb; // Ensure the sum is equal to the total

//   return {
//     lhrIsb,
//     isbLhr,
//   };
// };

// const AllTollPlaza = ({ tollPlazaData, selectedGroup, totalVehicleCount }) => {
//   if (!tollPlazaData || !tollPlazaData.tollPlazaData) {
//     return null;
//   }

//   console.log("Total vehicle count:", totalVehicleCount);  

//   const AllTollPlazaData = tollPlazaData.tollPlazaData;
//   const numRows = AllTollPlazaData.length;

//   console.log("Number of rows:", numRows); 

//   let remainingTotal = totalVehicleCount;

//   // Calculate row totals with proper distribution logic for Total Vehicle count
//   const rowTotals = Array.from({ length: numRows }, (_, index) => {
//     const baseCount = Math.floor(totalVehicleCount / numRows); // Base count for each row (without remainder)
//     const remainder = totalVehicleCount % numRows; // Remainder to distribute

//     // Initially distribute the base count
//     let rowCount = baseCount;

//     // Add one more vehicle to the first `remainder` rows
//     if (index < remainder) {
//       rowCount += 1;
//     }

//     // Store the row count
//     return rowCount;
//   });

//   // Check the sum of row totals
//   const sumOfRowTotals = rowTotals.reduce((acc, curr) => acc + curr, 0);
//   console.log("Sum of Row Totals:", sumOfRowTotals); // This should match totalVehicleCount

//   // Adjust the row totals slightly for variance (e.g., 21, 19, etc.)
//   // For example, you can apply a small variation to each row
//   const adjustedRowTotals = rowTotals.map((rowTotal) => {
//     const variation = Math.floor(Math.random() * 5) - 2; // Random variation between -2 and 2
//     return rowTotal + variation;
//   });

//   // Check final sum after adjustment
//   const finalSum = adjustedRowTotals.reduce((acc, curr) => acc + curr, 0);
//   console.log("Final Sum of Row Totals after adjustment:", finalSum); // This should now be equal to totalVehicleCount

//   return (
//     <Card>
//       <CardBody>
//         <CardTitle tag="h3" className="mb-4">
//           {selectedGroup ? selectedGroup.value || selectedGroup : "No Group Selected"}
//         </CardTitle>
//         <div className="table-responsive">
//           <table className="table table-striped table-custom table-bordered mb-0">
//             <thead>
//               <tr>
//                 <th>Toll Plaza</th>
//                 <th>Total Vehicle</th>
//                 <th>LHR-ISB</th>
//                 <th>ISB-LHR</th>
//               </tr>
//             </thead>
//             <tbody>
//               {AllTollPlazaData.map((TollPlaza, index) => {
//                 const totalForRow = adjustedRowTotals[index];
//                 console.log("Total for Row:", totalForRow); // Log the total for the current row
                
//                 // Calculate LHR-ISB and ISB-LHR with random distribution
//                 const { lhrIsb, isbLhr } = divideTotalIntoLHR_ISB_ISB_LHR(totalForRow);

//                 return (
//                   <tr key={index}>
//                     <td>{TollPlaza.TollPlaza}</td>
//                     <td>{totalForRow}</td>  {/* Display the total vehicle count for each toll plaza */}
//                     <td>{lhrIsb}</td>  {/* Random split for LHR-ISB */}
//                     <td>{isbLhr}</td>  {/* Remaining value for ISB-LHR */}
//                   </tr>
//                 );
//               })}
//             </tbody>
//           </table>
//         </div>
//       </CardBody>
//     </Card>
//   );
// };

// export default AllTollPlaza;

















// import React, { useState, useEffect } from "react";
// import { Card, CardBody, CardTitle } from "reactstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min";
// import "../Dashboard/Style/Style.css";

// // Function to divide total vehicle count into LHR-ISB and ISB-LHR
// const divideTotalIntoLHR_ISB_ISB_LHR = (total) => {
//   const lhrIsb = Math.floor(Math.random() * total); // Random value between 0 and total
//   const isbLhr = total - lhrIsb; // Ensure the sum is equal to the total
//   return {
//     lhrIsb,
//     isbLhr,
//   };
// };

// const AllTollPlaza = ({ tollPlazaData, selectedGroup, totalVehicleCount }) => {
//   const [randomizedTotals, setRandomizedTotals] = useState([]);

//   // Calculate row totals with proper distribution logic for Total Vehicle count
//   useEffect(() => {
//     if (tollPlazaData && tollPlazaData.tollPlazaData) {
//       const AllTollPlazaData = tollPlazaData.tollPlazaData;
//       const numRows = AllTollPlazaData.length;

//       let remainingTotal = totalVehicleCount;

//       // Calculate row totals with some distribution logic
//       const rowTotals = Array.from({ length: numRows }, (_, index) => {
//         const baseCount = Math.floor(totalVehicleCount / numRows); // Base count for each row
//         const remainder = totalVehicleCount % numRows; // Remainder to distribute

//         let rowCount = baseCount;
//         if (index < remainder) {
//           rowCount += 1;
//         }

//         return rowCount;
//       });

//       // Apply some random variation to the row totals
//       const adjustedRowTotals = rowTotals.map((rowTotal) => {
//         const variation = Math.floor(Math.random() * 5) - 2; // Random variation between -2 and 2
//         return rowTotal + variation;
//       });

//       // Calculate LHR-ISB and ISB-LHR for each row with the adjusted totals
//       const newRandomizedTotals = adjustedRowTotals.map((totalForRow) => {
//         const { lhrIsb, isbLhr } = divideTotalIntoLHR_ISB_ISB_LHR(totalForRow);
//         return { totalForRow, lhrIsb, isbLhr };
//       });

//       // Store the results in state
//       setRandomizedTotals(newRandomizedTotals);
//     }
//   }, [tollPlazaData, totalVehicleCount]); // Recalculate only when tollPlazaData or totalVehicleCount changes

//   if (!tollPlazaData || !tollPlazaData.tollPlazaData) {
//     return null;
//   }

//   return (
//     <Card>
//       <CardBody>
//         <CardTitle tag="h3" className="mb-4">
//           <p>Total Toll Plazas</p>
//           {/* {selectedGroup ? selectedGroup.value || selectedGroup : "No Group Selected"} */}
//         </CardTitle>
//         <div className="table-responsive">
//           <table className="table table-striped table-custom table-bordered mb-0">
//             <thead>
//               <tr>
//                 <th>Toll Plaza</th>
//                 <th>Total Vehicle</th>
//                 <th>LHR-ISB</th>
//                 <th>ISB-LHR</th>
//               </tr>
//             </thead>
//             <tbody>
//               {tollPlazaData.tollPlazaData.map((TollPlaza, index) => {
//                 const { totalForRow, lhrIsb, isbLhr } = randomizedTotals[index] || {};
//                 return (
//                   <tr key={index}>
//                     <td>{TollPlaza.TollPlaza}</td>
//                     <td>{totalForRow}</td> {/* Display the total vehicle count for each toll plaza */}
//                     <td>{lhrIsb}</td> {/* Random split for LHR-ISB */}
//                     <td>{isbLhr}</td> {/* Remaining value for ISB-LHR */}
//                   </tr>
//                 );
//               })}
//             </tbody>
//           </table>
//         </div>
//       </CardBody>
//     </Card>
//   );
// };

// export default AllTollPlaza;







import React, { useState, useEffect } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../Dashboard/Style/Style.css";

// Function to divide total vehicle count into LHR-ISB and ISB-LHR
const divideTotalIntoLHR_ISB_ISB_LHR = (total) => {
  const lhrIsb = Math.floor(Math.random() * total); // Random value between 0 and total
  const isbLhr = total - lhrIsb; // Ensure the sum is equal to the total
  return {
    lhrIsb,
    isbLhr,
  };
};

const AllTollPlaza = ({ tollPlazaData, selectedGroup, totalVehicleCount }) => {
  const [randomizedTotals, setRandomizedTotals] = useState([]);

  // Calculate row totals with proper distribution logic for Total Vehicle count
  useEffect(() => {
    if (tollPlazaData && tollPlazaData.tollPlazaData) {
      const AllTollPlazaData = tollPlazaData.tollPlazaData;
      const numRows = AllTollPlazaData.length;

      let remainingTotal = totalVehicleCount;

      // Calculate row totals with some distribution logic
      const rowTotals = Array.from({ length: numRows }, (_, index) => {
        const baseCount = Math.floor(totalVehicleCount / numRows); // Base count for each row
        const remainder = totalVehicleCount % numRows; // Remainder to distribute

        let rowCount = baseCount;
        if (index < remainder) {
          rowCount += 1;
        }

        return rowCount;
      });

      // Apply some random variation to the row totals
      const adjustedRowTotals = rowTotals.map((rowTotal) => {
        const variation = Math.floor(Math.random() * 5) - 2; // Random variation between -2 and 2
        return rowTotal + variation;
      });

      // Calculate LHR-ISB and ISB-LHR for each row with the adjusted totals
      const newRandomizedTotals = adjustedRowTotals.map((totalForRow) => {
        const { lhrIsb, isbLhr } = divideTotalIntoLHR_ISB_ISB_LHR(totalForRow);
        return { totalForRow, lhrIsb, isbLhr };
      });

      // Store the results in state
      setRandomizedTotals(newRandomizedTotals);

      // Sort the toll plazas based on the total vehicle count in descending order
      const sortedTollPlazaData = AllTollPlazaData.map((TollPlaza, index) => ({
        ...TollPlaza,
        totalForRow: newRandomizedTotals[index]?.totalForRow || 0, // Add the total vehicle count
        lhrIsb: newRandomizedTotals[index]?.lhrIsb || 0, // Add LHR-ISB count
        isbLhr: newRandomizedTotals[index]?.isbLhr || 0, // Add ISB-LHR count
      })).sort((a, b) => b.totalForRow - a.totalForRow); // Sort in descending order

      // Update the state with sorted data
      setRandomizedTotals(sortedTollPlazaData);
    }
  }, [tollPlazaData, totalVehicleCount]); // Recalculate only when tollPlazaData or totalVehicleCount changes

  if (!tollPlazaData || !tollPlazaData.tollPlazaData) {
    return null;
  }

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h3" className="mb-4">
          <p>Total Toll Plazas</p>
          {/* {selectedGroup ? selectedGroup.value || selectedGroup : "No Group Selected"} */}
        </CardTitle>
        <div className="table-responsive">
          <table className="table table-striped table-custom table-bordered mb-0">
            <thead>
              <tr>
                <th>Toll Plaza</th>
                <th>Total Vehicle</th>
                <th>LHR-ISB</th>
                <th>ISB-LHR</th>
              </tr>
            </thead>
            <tbody>
              {randomizedTotals.map((TollPlaza, index) => (
                <tr key={index}>
                  <td>{TollPlaza.TollPlaza}</td>
                  <td>{TollPlaza.totalForRow}</td> {/* Display the total vehicle count for each toll plaza */}
                  <td>{TollPlaza.lhrIsb}</td> {/* Random split for LHR-ISB */}
                  <td>{TollPlaza.isbLhr}</td> {/* Remaining value for ISB-LHR */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
};

export default AllTollPlaza;
