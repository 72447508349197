// import React from "react"
import { cryptoOrders, wallet } from "./crypto";
import { invoiceList } from "./invoices";
import { projects } from "./projects";
import { tasks } from "./tasks";
import {
  inboxmails,
  starredmails,
  importantmails,
  draftmails,
  sentmails,
  trashmails,
} from "./mails";
import {
  cartData,
  comments,
  customerData,
  discountData,
  orders,
  productsData,
  recentProducts,
  shops,
} from "./ecommerce";
import { chats, contacts, groups, messages } from "./chat";
import { calenderDefaultCategories, events } from "./calender";
import { users, userProfile } from "./contacts";
import { recentUsers, latestTransaction, latestOrders } from "./dashboard";
import {GalleryData} from "./gallery"
import { productData } from "./datatable";

export {
  productsData,
  discountData,
  events,
  calenderDefaultCategories,
  chats,
  groups,
  contacts,
  messages,
  orders,
  cartData,
  customerData,
  shops,
  recentProducts,
  comments,
  wallet,
  inboxmails,
  importantmails,
  draftmails,
  sentmails,
  trashmails,
  starredmails,
  cryptoOrders,
  invoiceList,
  projects,
  tasks,
  users,
  userProfile,
  recentUsers,
  latestTransaction,
  latestOrders,
  GalleryData,
  productData
};
