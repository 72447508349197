import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../Dashboard/Style/Style.css";

import {
  FaCar,
  FaBus,
  FaTruck,
  FaMotorcycle,
  FaQuestionCircle,
  FaShuttleVan,
} from "react-icons/fa";


const TotalVehicleCount = ({ tollPlazaData }) => {
  // Check if vehicleCountData is valid
  if (!tollPlazaData || !tollPlazaData.vehicleCountData) {
    // return <div>Loading...</div>; // Handle the loading state appropriately
    return null; // Render nothing
  }

  const VehicleCountData = tollPlazaData.vehicleCountData;

  // Aggregate data
  const aggregatedData = VehicleCountData.reduce((acc, curr) => {
    if (!acc[curr.Type]) {
      // Initialize the values, setting LHRTOISB and ISBTOLHR to 0 if they are undefined
      acc[curr.Type] = {
        ...curr,
        LHRTOISB: curr.LHRTOISB || 0, // Ensure LHRTOISB is initialized to 0
        ISBTOLHR: curr.ISBTOLHR || 0, // Ensure ISBTOLHR is initialized to 0
      };
    } else {
      // Aggregate the counts, ensuring to check for undefined values
      acc[curr.Type].VehicleCount += curr.VehicleCount || 0; // Add VehicleCount
      acc[curr.Type].LHRTOISB += curr.LHRTOISB || 0; // Add LHRTOISB
      acc[curr.Type].ISBTOLHR += curr.ISBTOLHR || 0; // Add ISBTOLHR
    }
    return acc;
  }, {});

  const aggregatedArray = Object.values(aggregatedData);

  // Define a mapping of vehicle types to icons
  const iconMap = {
    Car: <FaCar className="text-primary" size={20} />,
    Bus: <FaBus className="text-warning" size={20} />,
    Van: <FaShuttleVan className="text-success" size={20} />,
    Trucks: <FaTruck className="text-danger" size={20} />,
    Motorcycle: <FaMotorcycle className="text-info" size={20} />,
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h3" className="mb-4">
          Total Vehicle Count
        </CardTitle>
        <div className="table-responsive">
          <table className="table table-striped table-custom table-bordered mb-0">
            <thead>
              <tr>
                <th>Type</th>
                <th>Vehicle Count</th>
                <th>LHR-ISB</th>
                <th>ISB-LHR</th>
              </tr>
            </thead>
            <tbody>
              {aggregatedArray.map((Count, index) => (
                <tr key={index}>
                  <td>
                    <span className="d-flex align-items-center">
                      {iconMap[Count.Type] || (
                        <FaQuestionCircle className="text-muted" size={20} />
                      )}
                      <span className="ms-2">{Count.Type}</span>
                    </span>
                  </td>
                  <td>{Count.VehicleCount}</td>
                  <td>{Count.LHRTOISB}</td>
                  <td>{Count.ISBTOLHR}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
};

export default TotalVehicleCount;
